import React, { useEffect } from "react";
import logo from "./icon.svg";
import "./styles.css";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBb_nf9hn5YrcfbmbNegdvmmq2h-hVilPY",
  authDomain: "yello-space-1c108.firebaseapp.com",
  projectId: "yello-space-1c108",
  storageBucket: "yello-space-1c108.appspot.com",
  messagingSenderId: "496239107673",
  appId: "1:496239107673:web:c94703aff7a5dbb8dfdb48",
  measurementId: "G-B99LGD5Z1M",
};

function App() {
  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    getAnalytics(app);
  }, []);

  return (
    <>
      <img src={logo} className="yello-logo" alt="logo" />
      {/* <button className="contact">Contato</button> */}

      <section className="body">
        <div className="description">
          <h1 className="title">
            UM ESPAÇO <br /> DE INOVAÇÃO E TECNOLOGIA
          </h1>
          <p className="paragraph">Soluções personalizadas para startups e empresas em crescimento. </p>
        </div>
        <p className="corpy">Copyright © Yello Space | Todos os direitos reservados.</p>

        <a href="https://www.instagram.com/space.yello" target="_blank" rel="noreferrer">
          <svg
            className="instagram"
            width="37"
            height="37"
            viewBox="0 0 37 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M27.1505 0H10.2649C7.65345 0.00279355 5.14971 1.04145 3.3031 2.88806C1.45648 4.73467 0.417833 7.23841 0.415039 9.84991V26.7355C0.417833 29.347 1.45648 31.8507 3.3031 33.6973C5.14971 35.5439 7.65345 36.5826 10.2649 36.5854H27.1505C29.762 36.5826 32.2657 35.5439 34.1124 33.6973C35.959 31.8507 36.9976 29.347 37.0004 26.7355V9.84991C36.9976 7.23841 35.959 4.73467 34.1124 2.88806C32.2657 1.04145 29.762 0.00279355 27.1505 0ZM18.7077 26.7355C17.0379 26.7355 15.4056 26.2403 14.0172 25.3126C12.6288 24.3849 11.5466 23.0663 10.9076 21.5236C10.2686 19.9809 10.1014 18.2833 10.4272 16.6456C10.7529 15.0078 11.557 13.5035 12.7378 12.3227C13.9185 11.142 15.4229 10.3379 17.0606 10.0121C18.6984 9.68637 20.3959 9.85356 21.9386 10.4926C23.4813 11.1316 24.7999 12.2137 25.7276 13.6021C26.6553 14.9905 27.1505 16.6229 27.1505 18.2927C27.1482 20.5311 26.2579 22.6772 24.6751 24.2601C23.0923 25.8429 20.9462 26.7331 18.7077 26.7355ZM29.2612 9.84991C28.8437 9.84991 28.4357 9.72612 28.0886 9.49419C27.7415 9.26226 27.4709 8.93262 27.3112 8.54694C27.1514 8.16126 27.1096 7.73687 27.1911 7.32744C27.2725 6.918 27.4735 6.54191 27.7687 6.24673C28.0639 5.95154 28.44 5.75052 28.8494 5.66907C29.2589 5.58763 29.6832 5.62943 30.0689 5.78919C30.4546 5.94894 30.7842 6.21947 31.0162 6.56657C31.2481 6.91368 31.3719 7.32176 31.3719 7.73921C31.3719 8.299 31.1495 8.83587 30.7537 9.2317C30.3578 9.62753 29.821 9.84991 29.2612 9.84991ZM24.3362 18.2927C24.3362 19.4059 24.0061 20.4941 23.3877 21.4197C22.7692 22.3453 21.8901 23.0667 20.8617 23.4928C19.8332 23.9188 18.7015 24.0302 17.6097 23.8131C16.5178 23.5959 15.5149 23.0598 14.7278 22.2726C13.9406 21.4855 13.4045 20.4826 13.1874 19.3908C12.9702 18.2989 13.0816 17.1672 13.5076 16.1387C13.9337 15.1103 14.6551 14.2312 15.5807 13.6127C16.5063 12.9943 17.5945 12.6642 18.7077 12.6642C20.2005 12.6642 21.6321 13.2572 22.6877 14.3127C23.7432 15.3683 24.3362 16.7999 24.3362 18.2927Z"
              fill="#FAFAFA"
            />
          </svg>
        </a>
      </section>
    </>
  );
}

export default App;
